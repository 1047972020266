import React from "react"
import { graphql } from "gatsby"
import handleScroll from "../functions/handleScroll"
import Header from "../components/header"
import Meta from "../components/meta"
import TitleHeader from "../components/titleHeader"
import Footer from "../components/footer"
import "../styles/pages/_404.scss"

export default class page404 extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", handleScroll)
  }

  render() {
    const headerData = this.props.data.allContentfulHeader.nodes[0]

    return (
      <div className="page-404">
        <Meta
          title="404"
          description="The page you're looking for doesn't exist."
          image={headerData.logo.file.url}
          url={this.props.location.href}
          imgAlt={headerData.logo.description}
        />
        <Header />
        <TitleHeader title="404 | Not found" />
        <Footer />
      </div>
    )
  }
}

export const query = graphql`
  query page404Query {
    allContentfulHeader {
      nodes {
        logo {
          description
          file {
            url
          }
        }
      }
    }
  }
`
